<template>
   <Box class="progress_card is-clickable py-4 is-shadowless" :style="{'--bkgColor': 'hsl(48, 100%, 67%)' }">
      <Columns>
        <Column customClass="is-align-items-center">
          <!-- <div class="is-size-7 mr-2">#{{ item.iid }}</div> -->
          <div v-if="item.assignees.length > 0" class="">
            <figure class="image is-24x24 ml-auto">
              <img class="is-rounded" :src="item.assignees[0].avatar_url" :alt="item.assignees[0].name" :title="item.assignees[0].name">
            </figure>
            <!-- <div class="has-text-weight-medium">{{ item.assignees[0].name }}</div> -->
          </div>
        </Column>
        <Column customClass="is-8 is-align-items-center">
            <!-- <div class="is-size-7 mb-3">#{{ item.iid }}</div> -->
            <h3 class="progress_card__title has-text-weight-semibold title is-6 mb-0"  @click="$emit('titleClickHandle')">{{ item.title }}</h3>

            <!-- <div>
              <p>
                <small class="is-size-7">Spent: </small>
                <span class="has-text-weight-medium">{{ item.time_stats.human_total_time_spent ?  item.time_stats.human_total_time_spent: 'N/A' }}</span>
              </p>
            </div> -->
        </Column>
        <Column customClass="is-3 is-align-items-center">
            <project-name :id="item.project_id" />
        </Column>

      </Columns>
    </Box>
</template>

<script>
   import { defineAsyncComponent } from "vue";
  export default {
    components: {
      Columns: defineAsyncComponent(() => import('@/components/ds/Columns.vue')),
      Column: defineAsyncComponent(() => import('@/components/ds/Column.vue')),
      Box: defineAsyncComponent(() => import('@/components/ds/Box')),
      ProjectName: defineAsyncComponent(() => import('@/components/widgets/taskProgress/projectName')),
    },
    props: {
      item: {
        type: Object,
        required: true
      },
    },
  }
</script>

<style lang="scss" scoped>
  .progress_card {
    position: relative;
    border-radius: 0px;
    border-bottom: 1px solid #f5f5f5;

    /* &::before {
      content: "";
      display: block;
      background: var(--bkgColor);
      width: 6px;
      height: 100%;
    } */
  }
</style>